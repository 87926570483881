<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>

    <v-row justify="center">
      <v-col xl="6" lg="8" md="8" sm="10" xs="12">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="Type collaborateur" />
        </div>

        <!-- la progess bar à afficher lors du chargement des données -->
        <v-progress-linear indeterminate :active="loading"></v-progress-linear>

        <!-- le détail  -->
        <DetailViewBoxComponent
          title="Détail du type collaborateur"
        >
          <template v-slot:items>

            <InputFieldComponent label="label" :value="label" />
          
            <InputFieldComponent label="nom digital" :value="digitalName" />

            <InputFieldComponent label="est un type humain" :divider="false">
              <template v-slot:value>
                <v-checkbox
                  off-icon="mdi-checkbox-blank-outline"
                  on-icon="mdi-checkbox-outline"
                  color="primary"
                  :value="isPerson"
                  readonly
                  hide-details
                  class="my-0"
                ></v-checkbox>
              </template>
            </InputFieldComponent>
          </template>
        </DetailViewBoxComponent>
      </v-col>
    </v-row>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar>

  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import DetailViewBoxComponent from "@/components/ui/DetailViewBoxComponent.vue";
import InputFieldComponent from "@/components/ui/InputFieldComponent.vue";

import UsersTypeService from "@/service/user/user_type_service.js";

import * as exceptions from "@/service/exception_to_message.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";


export default {
  name: "DetailUserType",

  components: { Workflow, TitleAndReturnComponent, DetailViewBoxComponent, InputFieldComponent, },
  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin,],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /** le service de chargement. */
      userTypeService: null,

      /**l'identifiant  à visualiser. */
      userTypeId: null,

      /**Afficher le bouton de retour */
      showBackButton: false,

      /**les champs à afficher en détail */
      label: null,
      digitalName: null,
      isPerson: false,

    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        this.fields = [];

        let type = await this.userTypeService.getUserTypeById(this.userTypeId);

        this.init(type);

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    init(entity) {
      this.label = entity.label || "-";
      this.digitalName = entity.digitalName || "-";
      this.isPerson = entity.person || false;
    },
  },
  computed: {},
  mounted() {
    this.userTypeService = new UsersTypeService(this.$api);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.userTypeId = this.$route.params.id;
    }

    //on affiche le bouton retour
    this.showBackButton = true;

    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>